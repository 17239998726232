import { Component, OnInit, Input } from '@angular/core';
import { Router } from  "@angular/router";
import { BackendService } from '../../services/backend.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-selectlist',
  templateUrl: './selectlist.page.html',
  styleUrls: ['./selectlist.page.scss'],
})
export class SelectlistPage implements OnInit {
  @Input() title: string;
  @Input() back: string;
  @Input() hash: string;
  @Input() items = [];
  @Input() class: any;
  @Input() newButton: boolean;
  @Input() field: string;
  public itemsTmp = [];


  constructor(
    private  backendService:  BackendService,
    private  router:  Router,
    private location: Location,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    public alertController: AlertController
  ) {
    //this.hash = route.snapshot.fragment;

   }

   ngOnInit() {

   }

  ionViewWillEnter() {
    this.itemsTmp = this.class.optionsTemp;
  }

  public dismiss = () => {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }  

  onClick(item) {
    //this.backendService.setDataSubject(this.hash, item)
    this.class.itemClick(item);
    this.dismiss();
  }

  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.title,
      backdropDismiss:false,
      inputs: [
        {
          // name: 'FldPlateNo',
          name: this.field,
          type: 'text',
          placeholder: this.title
        }
      ],
      buttons: [
        {
         text: 'Ok',
         handler: (data) => {
            this.onClick(data)
         }
       },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  onChange(evt) {
    this.class.getItems(evt);
    var val = evt.target.value;
    this.itemsTmp = this.items.filter((item) => {
      return (
          val == "" || item['FldPlateNo'].toLowerCase().indexOf(val.toLowerCase()) > -1
        );
    })
  }


}
