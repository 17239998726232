import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectinputComponent } from './selectinput.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [SelectinputComponent],
  imports: [
    CommonModule,  FormsModule, ReactiveFormsModule 
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [SelectinputComponent]
})
export class SelectinputModule { }
