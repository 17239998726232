import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { BackendService } from './services/backend.service'

import {HttpError} from "./models/http-error";
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
// Regular dep. injection doesn't work in HttpInterceptor due to a framework issue (as of angular@5.2.9),
// use Injector directly (don't forget to add @Injectable() decorator to class).
constructor(
    private _injector: Injector, 
    public backendService: BackendService, 
    public alertController: AlertController    ) {

}

async presentAlert(header, message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
              window.location.reload()
          }
        }
      ]
    });

    await alert.present();
  }   

async errorHandler(exception: HttpErrorResponse) {

}

intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
    .pipe(
        catchError((exception, caught) => {
            const logFormat = 'background: maroon; color: white';
            this.backendService.dismissLoading(); 

            if (exception instanceof HttpErrorResponse) {
                switch (exception.status) {
        
                    case HttpError.BadRequest:
                        console.error('%c Bad Request 400', logFormat);
                        break;
        
                    case HttpError.Unauthorized:
                        console.error('%c Unauthorized 401', logFormat);
                        window.location.href = '/login' + window.location.hash;
                        break;
        
                    case HttpError.NotFound:
                        //show error toast message
                        console.error('%c Not Found 404', logFormat);
                        /*
                        const _toaster = this._injector.get(Toaster),
                            _router = this._injector.get(Router);
                        _toaster.show({
                            message: exception.error && exception.error.message ? exception.error.message : exception.statusText,
                            typeId: 'error',
                            isDismissable: true
                        });
                        _router.navigate(['']);
                       */
                        break;
        
                    case HttpError.TimeOut:
                        // Handled in AnalyticsExceptionHandler
                        console.error('%c TimeOut 408', logFormat);
                        break;
        
                    case HttpError.Forbidden:
                        console.error('%c Forbidden 403', logFormat);
                        break;
        
                    case HttpError.InternalServerError:
                        console.error('%c big bad 500', logFormat);
                        break;
                    }
                }    
            this.presentAlert("SERVER ERROR", exception.message)
            return throwError(exception.message || 'server Error');
        })

    )
}

}