import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectlistPage } from  "../../pages/selectlist/selectlist.page";
import { ModalController } from '@ionic/angular';
import { BackendService } from '../../services/backend.service';
import { $ } from 'protractor';

@Component({
  selector: 'app-selectinput',
  templateUrl: './selectinput.component.html',
  styleUrls: ['./selectinput.component.scss'],
})
export class SelectinputComponent implements OnInit {
  @Input() label: string
  @Input() fieldName: string
  @Input() idField: string = "FldId";
  @Input() nameField: string = "FldName";
  @Input() nameField2: string;
  @Input() filterInput: string;
  @Input() filterField: string;
  @Input() inputLabelField: string;
  @Input() prefixField: string;
  @Input() prefixSeparator: string;
  @Input() relatedInput: string;
  @Input() value: string;
  @Input() clear: string;
  @Input() fields = []
  @Input() options = []
  @Input() required = false
  public   optionsTemp = [];
  private optionFiltered = [];
  @Input() parent: UntypedFormGroup;
  @Input() changevalue: Function;
  @Input() keypress: Function;
  @Input() disabled: string = "false"
  public showList = false;
  public searchValue = "";
  public inputValue: any;
  @ViewChild('inp') input;
  @ViewChild('srch') search;
  @Input() labelPosition: string = "floating";
  @Input() promptField: string = "";
  @Input() newButton: boolean = false;
  @Input() noReset: boolean = false;
  private notShowList = false;
  private focused: boolean = false;


  constructor(
    public modalController: ModalController,
    public backendService: BackendService

  ) { 
  }
  
  ngOnInit() {
    //this.optionsTemp = this.options;
    this.backendService.getDataSubject("formReset").subscribe(r => {
      if(!this.noReset)
        this.reset();
    })
    this.backendService.getDataSubject("selectText").subscribe(r => {
      if(r.id == this.fieldName) {
        this.searchValue = r.text;
      }
    })

    if (this.value) this.searchValue = this.getText(this.value);

  }

  getText(value) {
    const found = this.options.find((item) => item[this.idField] == value);
    if (found) return found[this.nameField].trim();
    return '';
  }

  filterOptions() {
    //debugger;
    if(!this.filterField) {
      this.optionFiltered = this.options;
      this.optionsTemp = this.optionFiltered;
      return true;
    }
    var me = this;
    //this.optionsTemp
    this.optionFiltered = this.options.filter(function(option) {
      
      return option[me.filterField] == me.parent.controls[me.filterInput].value;

    })
    this.optionsTemp = this.optionFiltered;
    

    

  }

  onChange(e, item?:any) {
    //debugger;
    this.parent.controls[this.fieldName].setValue(e);
    // console.log(this.changevalue, e);
    if(typeof this.changevalue == "function") {
      this.changevalue(e, item)
    }
  }

  getItems(ev: any) {
    // Reset items back to all of the items

    // set val to the value of the searchbar
    const val = ev.target.value;
    //this.showList = val != "";
    if(this.notShowList) {
      this.notShowList = false;
      return;
    }
    this.showList = true;
    // if the value is an empty string don't filter the items
    if (val) {
        
        this.optionsTemp = this.optionFiltered.filter((item) => {
          return (
              item[this.prefixField] && item[this.nameField] && (item[this.prefixField]+this.prefixSeparator+item[this.nameField].toLowerCase()).indexOf(val.toLowerCase()) > -1 ||
              item[this.nameField] && item[this.nameField].toLowerCase().indexOf(val.toLowerCase()) > -1 ||
              item[this.nameField2] && item[this.nameField2].toLowerCase().indexOf(val.toLowerCase()) > -1 ||
              item[this.idField] && item[this.idField].toString() == val 
            );
        })
    } else {
      //ev.target.value = 0;
      this.optionsTemp = this.optionFiltered;
    }
  }

  onFocus() {
    if(this.notShowList) { 
      this.notShowList = false;
      setTimeout(() => {
        this.filterOptions();
        this.showList = true;
        }, 200)
      return;
    }

    this.focused = true;
    this.filterOptions();
    this.showList = true;

    setTimeout(() => {
      this.filterOptions();
      this.showList = true;
      }, 200) 
  }

  onBlur(e?, newtarget = null) { 
    this.focused = false;
    setTimeout(() => {
      if(e && e.target.value == "") {
          this.onChange(e.target.value);
      }
 
      this.showList = false;
    }, 200)//500
  } 

  setValueID(id) {
    var items = [];
    if((typeof this.options != "undefined") && this.options.length)
      items = this.options.filter((item) => { 
        return item[this.idField] == id;
      })
    if(items.length) {
      this.itemClick(items[0]);
    } else {
      this.reset();
    }
  }

  mouseEnter(e)
  {
    console.log(e.target.value) 
  }

  caretClick() { 
    // console.log("caret");
    this.showList = !this.showList;

  }
 

  itemClick(item) {  
    if(typeof item == "undefined") return;
    this.searchValue = (typeof item[this.prefixField] != "undefined" && item[this.prefixField]) ? item[this.prefixField] + this.prefixSeparator : "" ;
    this.searchValue += (typeof item[this.nameField2] != "undefined" && item[this.nameField2]) ? item[this.nameField] + " " + item[this.nameField2] : item[this.nameField] ;
    
    // console.log(this.inputLabelField,this.relatedInput, this.searchValue, item)
    if(this.inputLabelField && this.relatedInput) {
      this.parent.controls[this.relatedInput].setValue(item[this.inputLabelField]);
    }
    
    this.search.nativeElement.value = this.searchValue;
    this.inputValue = item[this.idField];
    this.input.nativeElement.value = this.inputValue;
    this.onBlur();
    this.onChange(this.inputValue, item)
    
  }

  inputChange(e) { 
     console.log("inputChange", e);
    this.setValueID(e.detail.value)
  }

  onKeyPress(e) {  
    if(typeof this.keypress == "function") {
      this.keypress(e);
    }
  }




  async onInputClick() {
    let field = '';
    if (this.fieldName == 'PrmTruckID') field = 'FldPlateNo';
    if (this.fieldName == 'PrmTrolleyID') field = 'FldPlateNo';
    if (this.fieldName == 'PrmDriverID') field = 'DriverName';
    this.filterOptions();
    const modal = await this.modalController.create({
      component: SelectlistPage,
      cssClass: 'my-custom-class',
      backdropDismiss:false,
      componentProps: {
        'hash': this.fieldName, 
        'back': window.location.pathname,
        'title': this.fields[this.label].FldText,
        'items': this.optionsTemp,
        'newButton': this.newButton,
        'class': this,
        'field': this.promptField ? this.promptField : field
      }
    });
    return await modal.present();
}
  
  onClear(e) {
    this.reset();
    this.parent.controls[this.fieldName].reset();
    if(typeof this.keypress == "function") {
      this.keypress(e)
    }
}

  reset() {
    this.searchValue = "";
    this.notShowList= true;
  }


}
