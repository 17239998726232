import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(this.authService.isLoggedIn()){
        // console.log(this.authService.isLoggedIn())
        return true;
      }
      else{
        return new Promise((resolve) => {
          this.authService.checkUserExists().then((tokenExists)=>{
            // console.log(tokenExists)
  
            if(tokenExists){
              resolve(true);
            }
            else{
              // console.log("no")

              this.router.navigateByUrl('/login');
              resolve(false);
            }
          })
        })
      }        

    }
  
}
