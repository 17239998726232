import { Injectable } from  '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { tap } from  'rxjs/operators';
import { Observable, BehaviorSubject } from  'rxjs';
import { environment } from '../../environments/environment';
import { BackendService } from '../services/backend.service';

import { Storage } from  '@ionic/storage';
import { User } from  './user';
import { AuthResponse } from  './auth-response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  BACKEND_URL:  string  =  environment.BACKEND_URL;
  authSubject  =  new  BehaviorSubject(false);

  constructor(
    private  backendService:  BackendService,
    private  httpClient:  HttpClient, 
    private  storage:  Storage) { }

  register(user: User): Observable<AuthResponse> {
    return this.httpClient.post<AuthResponse>(`${this.BACKEND_URL}/register`, user).pipe(
      tap(async (res:  AuthResponse ) => {

        if (res.user) {
          await this.storage.set("ACCESS_TOKEN", res.token);
          this.authSubject.next(true);
        }
      })

    );
  }


  async logout() {
    await this.storage.remove("USER");
    await this.storage.remove("ACCESS_TOKEN");
    this.authSubject.next(false);
  }

  isLoggedIn() {
    this.checkUserExists();
    return this.authSubject.value;
  }      

  checkTokenExists(): Promise<boolean>{
    return new Promise((resolve)=>{
      this.storage.get("ACCESS_TOKEN").then(token => {
        if(token !== null){
          this.authSubject.next(true);
          resolve(true);
        }
        else
        {
          this.authSubject.next(false);
          resolve(false);
        }
      })
    })
  }

  checkUserExists(): Promise<boolean>{
    return new Promise((resolve)=>{
      this.storage.get("USER").then(user => {
        // console.log
        if(user !== null){
          this.backendService.setUserId(user.FldId)
          this.authSubject.next(true);
          resolve(true);
        }
        else
        {
          this.authSubject.next(false);
          resolve(false);
        }
      })
    })
  }  


}
