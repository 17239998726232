import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'chpassword',
    loadChildren: () => import('./pages/chpassword/chpassword.module').then( m => m.ChpasswordPageModule)
  },
  {
    path: 'weight',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/firstweight/firstweight.module').then( m => m.FirstweightPageModule)
  },
  {
    path: 'selectlist',
    loadChildren: () => import('./pages/selectlist/selectlist.module').then( m => m.SelectlistPageModule)
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'Stations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/stations/stations.module').then( m => m.StationsPageModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'rols',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/rols/rols.module').then( m => m.RolsPageModule)
  },
  {
    path: 'import',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/import/import.module').then( m => m.ImportPageModule)
  },
  {
    path: 'cities',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/cities/cities.module').then( m => m.CitiesPageModule)
  },
  {
    path: 'supplier',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/supplier/supplier.module').then( m => m.SupplierPageModule)
  },
  {
    path: 'customer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/customer/customer.module').then( m => m.CustomerPageModule)
  },
  {
    path: 'suppliertype',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/suppliertype/suppliertype.module').then( m => m.SuppliertypePageModule)
  },
  {
    path: 'supplierfield',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/supplierfield/supplierfield.module').then( m => m.SupplierfieldPageModule)
  },
  {
    path: 'trucks',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/trucks/trucks.module').then( m => m.TrucksPageModule)
  },
  {
    path: 'trolleys',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/trolleys/trolleys.module').then( m => m.TrolleysPageModule)
  },
  {
    path: 'continers',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/continers/continers.module').then( m => m.ContinersPageModule)
  },
  {
    path: 'truckscompany',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/truckscompany/truckscompany.module').then( m => m.TruckscompanyPageModule)
  },
  {
    path: 'menu',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule)
  },
  {
    path: 'subreport',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/subreport/subreport.module').then( m => m.SubreportPageModule)
  },
  {
    path: 'manualweight',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/manualweight/manualweight.module').then( m => m.ManualweightPageModule)
  },
  {
    path: 'tanks',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tanks/tanks.module').then( m => m.TanksPageModule)
  },
  {
    path: 'tankreport',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tankreport/tankreport.module').then( m => m.TankreportPageModule)
  },
  {
    path: 'truckinsite',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/truckinsite/truckinsite.module').then( m => m.TruckinsitePageModule)
  },
  {
    path: 'materials',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/materials/materials.module').then( m => m.MaterialsPageModule)
  },
  {
    path: 'screenobjects',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/screenobjects/screenobjects.module').then( m => m.ScreenobjectsPageModule)
  },
  {
    path: 'screens',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/screens/screens.module').then( m => m.ScreensPageModule)
  },
  {
    path: 'editweight',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/editweight/editweight.module').then( m => m.EditweightPageModule)
  },
  {
    path: 'devices',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/devices/devices.module').then( m => m.DevicesPageModule)
  },
  {
    path: 'drivers',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/drivers/drivers.module').then( m => m.DriversPageModule)
  },
  {
    path: 'groups',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/group/group.module').then( m => m.GroupPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
