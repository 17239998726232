import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-lang-popover',
  templateUrl: './lang-popover.page.html',
  styleUrls: ['./lang-popover.page.scss'],
})
export class LangPopoverPage implements OnInit {

  public langs = []

  constructor(
    private popover:PopoverController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.langs = this.navParams.get('langs');
  }

  setLang(lang) {
    this.popover.dismiss(lang);
  } 


}
