import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS } from  '@angular/common/http';
import { CommonModule } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from  './auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LangPopoverPage } from './components/lang-popover/lang-popover.page';
import { ErrorInterceptor } from './error-interceptor';
import { BackendService } from './services/backend.service';

/* PAGES */
import { LoginPageModule } from './auth/login/login.module'
import { HeaderModule } from './components/header/header.module'
import { FooterModule } from './components/footer/footer.module'
import { SelectinputModule } from "./components/selectinput/selectinput.module"
import { TextinputModule } from "./components/textinput/textinput.module"
import { WeightformModule } from './components/weightform/weightform.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetimex';
import { ServiceWorkerModule } from '@angular/service-worker';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
} 
@NgModule({
    declarations: [
        AppComponent,
        LangPopoverPage
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule, CommonModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule, BrowserAnimationsModule, SelectinputModule,
        IonicModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AppRoutingModule, FooterModule,
        //HeaderModule, SelectinputModule, TextinputModule, 
        AuthModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: true })
    ],
    providers: [
        BackendService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
